<template>
  <div class="menuView" v-loading="loading" element-loading-text="加载中">
    <div class="leftMenu">
      <el-tree 
       highlight-current
       :render-content="renderContent" :data="treeData" @node-click="handleNodeClick" default-expand-all :props="defaultProps"  node-key="value" :expand-on-click-node="false"></el-tree>
    </div>
    <div class="rightMenu">
      <div class="tab">
        <div v-for="(item,index) in topTab" @click="tabChange(index)">
          <span :class="item.selected?'selectedCol':''">{{item.label}}</span>
          <div class="divBg" v-show="item.selected"></div>
        </div>
      </div>

      <div class="operation">
        <div>
          <el-form :model="form" :rules="rules" ref="form">
            <el-form-item label="菜单名称" :label-width="formLabelWidth" prop="menuName">
              <el-input v-model="form.menuName" autocomplete="off" class="centerCzInput cdInput" placeholder="请输入">
              </el-input>
            </el-form-item>

            <el-form-item label="唯一标识" :label-width="formLabelWidth" >
              <el-input v-model="form.code" autocomplete="off" class="centerCzInput cdInput" placeholder="请输入">
              </el-input>
            </el-form-item>

            <el-form-item label="菜单排序" :label-width="formLabelWidth" prop="sort">
              <el-input v-model="form.sort" autocomplete="off" class="centerCzInput cdInput" placeholder="请输入">
              </el-input>
            </el-form-item>

            <el-form-item label="菜单URL" :label-width="formLabelWidth" prop="url">
              <el-input v-model="form.url" autocomplete="off" class="centerCzInput cdInput" placeholder="请输入">
              </el-input>
            </el-form-item>

            <el-form-item label="菜单图标" :label-width="formLabelWidth" prop="icon">
              <el-input v-model="form.icon" autocomplete="off" class="centerCzInput cdInput" placeholder="请输入">
              </el-input>
            </el-form-item>

            <el-form-item label="是否为菜单项" :label-width="formLabelWidth" prop="isMenu">
              <el-radio-group v-model="form.isMenu">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
              </el-input>
            </el-form-item>

            <el-form-item label="">

            </el-form-item>
          </el-form>
          <el-button class="centerCzbtn ml100" @click="confirmCancellation" :loading="isLoading">{{topTab[0].selected?'添加':"保存"}}</el-button>
          <button class="centerBtn resetbtn ml20" @click="addAccount=false" v-show="topTab[1].selected">取消</button>
        </div>
        <!-- <div v-show="topTab[2].selected" class="addFunction">
          <div><button class="centerCzbtn">添加功能按钮</button></div>
          <div><button class="centerBtn">编辑功能按钮</button></div>
          <div><button class="centerBtn">删除功能按钮</button></div>
        </div> -->
      </div>
    </div>

          <!-- 删除 -->
      <el-dialog title="删除" :visible.sync="delDia" width="30%">
        <span>确认删除吗</span>
        <span slot="footer" class="dialog-footer">
          <button class="centerBtn" @click="delConfirm">确 定</button>
          <button class="centerBtn resetbtn ml20" @click="delDia = false">
            取 消
          </button>
        </span>
      </el-dialog>
  </div>
</template>

<script>
import {
  _DeleteMenuCenter,
  _AddMenuCenter,
  _UpdateMenuCenter,
} from "@/api/sassManagement/menuManageSass";
import { _FindAllPage } from "@/api/selCar";
export default {
  data() {
    return {
      formLabelWidth: "100px",
      rowData: {},
      delDia: false,
      isLoading: false,
      rowId: "",
      menuCenterId: "",
      defaultProps: {
        children: "children",
        label: "menuName",
      },
      level: 1,
      topTab: [
        {
          label: "添加下级菜单",
          selected: true,
        },
        {
          label: "编辑菜单",
          selected: false,
        },
        // {
        //   label: '添加功能按钮',
        //   selected: false
        // },
      ],
      loading: false,
      form: {
        menuName: "",
        code: "",
        sort: "",
        url: "",
        icon: "",
        isMenu: "1",
        parentId: 0,
        menuType: 1,
        level: 1,
      },
      rules: {
        menuName: [
          {
            required: true,
            message: "请输入菜单名称",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入唯一标识",
            trigger: "blur",
          },
        ],
      },
      treeData: [
        {
          menuName: "根菜单",
          id: 0,
          children: [],
        },
      ],
    };
  },
  mounted() {
    this.getTreeData();
  },
  methods: {
    delMenuShow(data) {
      this.menuCenterId = data.id;
      this.delDia = true;
    },
    //确认删除
    async delConfirm() {
      let { success, data, message } = await _DeleteMenuCenter({
        menuId: this.menuCenterId,
      });
      if (success) {
        this.$message.success("删除成功");
        this.delDia = false;
        (this.form.menuName = ""),
          (this.form.code = ""),
          (this.form.sort = ""),
          (this.form.url = ""),
          (this.form.icon = ""),
          (this.form.isMenu = "1"),
          (this.form.parentId = 0),
          (this.form.menuType = 1),
          (this.form.level = 1),
          this.getTreeData();
      } else {
        this.$message.error(message);
      }
    },
    renderContent(h, { node, data, store }) {
      if (node.level == 1) {
        return (
          <div class="parentCla">
            <span>{data.menuName}</span>
          </div>
        );
      } else {
        return (
          <div class="sonCla">
            <span>{data.menuName}</span>
            <span class="treeSty" on-click={() => this.delMenuShow(data)}>
              删除
            </span>
          </div>
        );
      }
      // if (data.level !=2) {
      //   return (
      //     <div>
      //       <span>{data.typeName}</span>
      //       <span
      //         class="treeSty"
      //         on-click={() => this.getAdd(node, data, store)}
      //       >
      //         添加
      //       </span>
      //       <span class="treeSty" on-click={() => (this.delDia = true)}>
      //         删除
      //       </span>
      //     </div>
      //   );
      // } else {
      //   return (
      //     <div>
      //       <span>{data.typeName}</span>
      //       <span class="treeSty" on-click={() => (this.delDia = true)}>
      //         删除
      //       </span>
      //     </div>
      //   );
      // }
    },
    tabChange(index) {
      this.topTab.forEach((e, i) => {
        this.topTab[i].selected = false;
      });
      this.topTab[index].selected = true;
      if (index == 1) {
        this.form.icon = this.rowData.icon;
        this.form.isMenu = this.rowData.isMenu + "";
        this.form.menuName = this.rowData.menuName;
        this.form.code = this.rowData.code;
        this.form.sort = this.rowData.sort;
        this.form.url = this.rowData.url;
        this.form.menuType = this.rowData.menuType;
        this.form.level = this.rowData.level;
      } else {
        this.form.icon = "";
        this.form.isMenu = "1";
        this.form.menuName = "";
        this.form.code = "";
        this.form.sort = "";
        this.form.url = "";
        this.form.menuType = this.rowData.menuType;
        this.form.level = 1;
      }
    },
    handleNodeClick(data, node) {
      this.rowId = data.id;
      this.level = node.level;
      this.form.level = node.level;
      console.log(this.level);
      if (data.id == 0) {
        this.level = 0;
        this.form.parentId = 0;
      } else {
        this.form.parentId = data.id;
        this.rowData = data;
      }
      if (this.topTab[0].selected) {
      } else {
        this.form.icon = data.icon;
        this.form.isMenu = this.form.isMenu + "";
        this.form.menuName = data.menuName;
        this.form.code = data.code;
        this.form.sort = data.sort;
        this.form.url = data.url;
        this.form.menuType = data.menuType;
        this.form.level = data.level;
      }
    },
    confirmCancellation() {
      this.topTab[0].selected ? this.addMenu() : this.updateRole();
    },
    // 添加角色
    addMenu() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          this.isLoading = true;
          _AddMenuCenter(this.form).then((res) => {
            if (!res) {
              this.isLoading = false;
            }
            if (res.success) {
              this.$message.success("添加成功");
              this.form.menuName = "";
              this.form.code = "";
              this.form.sort = "";
              this.form.url = "";
              this.form.icon = "";
              this.form.isMenu = "1";
              this.form.level = "1";
              this.getTreeData();
              this.isLoading = false;
            } else {
              this.$message.error(res.message);
              this.isLoading = false;
            }
            this.loading = false;
          });
        }
      });
    },
    //修改角色
    async updateRole() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.rowId == 0) {
            this.$message.error("不能修改根菜单");
          } else {
            this.loading = true;
            this.isLoading = true;
            let rolObj = JSON.parse(JSON.stringify(this.form));
            rolObj.id = this.rowId;
            console.log(rolObj);
            _UpdateMenuCenter(rolObj).then((res) => {});
            if (!res) {
              this.isLoading = false;
            }
            if (res.success) {
              this.$message.success("修改成功");
              this.getTreeData();
              this.isLoading = false;
            } else {
              this.$message.error(message);
              this.isLoading = false;
            }
            this.loading = false;
          }
        }
      });
    },
    async getTreeData() {
      let { success, data, message } = await _FindAllPage({ type: 1 });
      this.treeData[0].children = data;
    },
  },
};
</script>

<style>
.leftMenu {
  width: 30%;
  height: 100%;
  background-color: #fff;
  padding: 10px;
  overflow: auto;
}
.leftMenu::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.el-tree {
  /* margin-top: 10px; */
}

.menuView {
  height: 100%;
  display: flex;
}

.ml100 {
  margin-left: 100px;
}

.rightMenu {
  width: 70%;
  height: 100%;
  background-color: #fff;
  margin-left: 20px;
  padding: 20px 30px 0px;
}

.tab {
  display: flex;
  color: #666666;
  font-size: 16px;
}

.tab > div {
  margin-right: 75px;
  cursor: pointer;
  position: relative;
}

.selectedCol {
  color: #1683d9;
  font-weight: 550;
}

.treeSty {
  color: #1683d9;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 550;
}

.operation {
  margin-top: 40px;
}

.cdInput {
  width: 360px;
}

.addFunction > div {
  margin-bottom: 40px;
}
</style>